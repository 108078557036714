import { Controller } from "stimulus"

export default class extends Controller {
    reset() {
        this.element.reset()
        $("#dailyReportForm").remove();
        $('.new-daily-report').html("<div class='col-12'><p class='mb-0'>本日の日報は提出済みです。日報を提出くださりありがとうございます。</p></div>");
    }
    
    closemodal() {
        $(`#daily_reportModal-${daily_report.id}`).modal('hide')
    }
}