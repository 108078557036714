import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
        console.log("this started")
    }

    resetMobile() {
        this.element.reset();
        $('textarea').each(function () {
          this.setAttribute('style', 'height:' + 40 + 'px;border-radius: 1.0rem;');
        });
        let footer = document.querySelector('.mobile-footer-nav');
        let footer_height = footer.offsetHeight;
        let message_header = document.querySelector('#mobile-message-header');
        let message_header_height = message_header.offsetHeight;
        let form_wrapper = document.querySelector('#mobile-message-form');
        let form_height = form_wrapper.offsetHeight;
        let message_height = screen.height - 60 - message_header_height - 180 - form_height - footer_height;
        document.getElementById("message-wrapper").style.height = message_height + 'px';

    }

    resetDesktop() {
        this.element.reset();
    }
}
