document.addEventListener("turbolinks:load", function() {
  $input = $("[data-behavior='autocomplete']")
  var options = {
    getValue: function (element) {
        return element.number + ' ' + element.location
    },
    url: function(phrase) {
      return "/search.json?q=" + phrase;
    },
    categories: [
      {
        listLocation: "constructions",
        header: "<strong>工事番号</strong>",
      }, 
      {
        listLocation: "locations",
        header: "<strong>現場名</strong>",
      }
    ],
    list: {
      onChooseEvent: function() {
        var url = $input.getSelectedItemData().url
        $input.val("")
        Turbolinks.visit(url)
      }
    }
  }
  $input.easyAutocomplete(options)
});