import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "links", "template", "unit_count", "cost_per_unit", "amount_with_tax" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    console.log(this.wrapperClass)
  }

  add_association(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.linksTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
    $(document).ready(function() {
      var sum = 0;
      //iterate through each textboxes and add the values
      $('.nested-fields').each(function(i, el) {
      var unit_count = $("#unit_count", el).val();
      var cost_per_unit = $("#cost_per_unit_dirty", el).val();
      var amount_without_tax = parseInt(unit_count) * parseInt(cost_per_unit.replace(/,/g, ''));
      $("#cost_per_unit_sanitized", el).val(parseInt(cost_per_unit.replace(/,/g, '')));
      $("#amount_without_tax_sanitized", el).val(amount_without_tax);
      $("#amount_without_tax_dirty", el).val(amount_without_tax);
      var awotsCollection = document.getElementsByClassName("amount_without_tax_dirty");
      var awots = Array.from(awotsCollection);
      awots.forEach(function (awot) {
        new Cleave(awot, {
              numeral: true,
              numeralThousandsGroupStyle: 'thousand'
          })
      });
      sum += parseInt(amount_without_tax);
      });
      $("#total_invoice_amount_without_tax_sanitized").val(sum);
      $("#total_invoice_amount_without_tax_dirty").val(sum);
      var cleave = new Cleave('#total_invoice_amount_without_tax_dirty', {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
      });
      var total_invoice_amount_without_tax = $("#total_invoice_amount_without_tax_sanitized").val();
      var total_invoice_tax = parseInt(total_invoice_amount_without_tax) * 0.1;
      $("#total_invoice_tax_sanitized").val(total_invoice_tax);
      $("#total_invoice_tax_dirty").val(total_invoice_tax);
      var cleave = new Cleave('#total_invoice_tax_dirty', {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
      });
      var total_invoice_amount_with_tax = parseInt(total_invoice_amount_without_tax) + parseInt(total_invoice_tax);
      $("#total_invoice_amount_with_tax_sanitized").val(total_invoice_amount_with_tax);
      $("#total_invoice_amount_with_tax_dirty").val(total_invoice_amount_with_tax);
      var cleave = new Cleave('#total_invoice_amount_with_tax_dirty', {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand'
      });
    });
  }
}