import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  change(event) {
    let subsidiary = event.target.selectedOptions[0].value
    get(`/customer_invoices/subsidiary?subsidiary=${subsidiary}`, {
      responseKind: "turbo_stream"
    })
  }
}