import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ["form"]
  }

  submit(event) {
    this.formTarget.requestSubmit()
  }
}