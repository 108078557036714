document.addEventListener("turbolinks:load", function() {
  $("#new_addition").validationEngine({
    promptPosition: "inline"
  });
  $(".edit_addition").validationEngine({
    promptPosition: "inline"
  });
  $("#new_order").validationEngine({
    promptPosition: "inline"
  });
  $(".edit_order").validationEngine({
    promptPosition: "inline"
  });
  $("#new_construction").validationEngine({
    promptPosition: "inline"
  });
  $(".edit_construction").validationEngine({
    promptPosition: "inline"
  });
  $("#new_construction_report").validationEngine({
    promptPosition: "inline"
  });
  $(".edit_construction_report").validationEngine({
    promptPosition: "inline"
  });
  $("#new_comment").validationEngine({
    promptPosition: "inline"
  });
  $("#new_customer_invoice").validationEngine({
    promptPosition: "inline"
  });
  $("#edit_customer_invoice").validationEngine({
    promptPosition: "inline"
  });
});