// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from '@rails/ujs';

// NOTE: turbo drive default to disabled, please see issue #159 relating to
// page freeze when loading large amount of DOMs when rendering /orders.
import { Turbo } from '@hotwired/turbo-rails';
Turbo.session.drive = false;

import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import '@hotwired/turbo-rails';
global.toastr = require('toastr');
require('chartkick');
require('chart.js');
require('cleave.js');
require('easy-autocomplete');
require('datatables.net-bs4');
require('./validation');

Rails.start();
ActiveStorage.start();

require('bootstrap');

import 'bootstrap/dist/css/bootstrap';
import 'packs/search';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import 'bootstrap-select';
import 'controllers';

// Not sure why but by this point there are already conflicting jQuery instances at the global scope
// (e.g. jQuery !== window.jQuery !== window.JQUERY !== ...)
// This is most likely due to a misconfig in webpack.
// The code below fixes this by unifying all jQuery instances to one.
import globalJQuery from 'jquery';
globalJQuery.noConflict();
$ = globalJQuery;
jQuery = globalJQuery;
window.$ = globalJQuery;
window.jQuery = globalJQuery;

// jQuery plugins
import('./jquery.validationEngine');
import('./jquery.validationEngine-ja');
require('./jquery.jpostal');
require('./jquery.mask');
import 'jquery_nested_form';

import 'select2';
import './select2-ja';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap';
window.patchVendorSelect2 = (element, options = {}) => {
  window.jQuery(() => {
    element.select2({
      ajax: {
        data: (params) => ({ q: params.term }),
        processResults: (data) => ({
          results: data.map((vendor) => ({
            id: vendor.id,
            text: vendor.code_with_long_name,
          })),
        }),
        url: '/vendors/search.json',
        dataType: 'json',
      },
      language: 'ja',
      ...options,
    });
  });
};

// https://getbootstrap.com/docs/4.6/components/forms/#file-browser
import bsCustomFileInput from 'bs-custom-file-input';
window.jQuery(() => {
  bsCustomFileInput.init();
});
// Also expose to global so we can manually run this again when render new input
window.bsCustomFileInput = bsCustomFileInput;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import AutoNumeric from 'autonumeric';
window.AutoNumeric = AutoNumeric;
