/**
 * HEADS UP! This file is modified library source code
 *
 * This is a modified version of select2/intl/ja to:
 * - 1. Enforce the correct jQuery instance (because Webpack injects the wrong one)
 * - 2. Guard the code after jQuery is ready (because AMD conflicts with our bundling)
 * - 3. Custom Japanese
 */

/*! Select2 4.1.0-rc.0 | https://github.com/select2/select2/blob/master/LICENSE.md */

/** 2 */
window.jQuery(() => {
  !(function () {
    jQuery = window.jQuery;
    /** 1 */
    if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) var n = jQuery.fn.select2.amd;
    n.define('select2/i18n/ja', [], function () {
      return {
        errorLoading: function () {
          return '結果が読み込まれませんでした';
        },
        inputTooLong: function (n) {
          return n.input.length - n.maximum + ' 文字を削除してください';
        },
        inputTooShort: function (n) {
          return '少なくとも ' + (n.minimum - n.input.length) + ' 文字を入力してください';
        },
        loadingMore: function () {
          return '読み込み中…';
        },
        maximumSelected: function (n) {
          return n.maximum + ' 件しか選択できません';
        },
        noResults: function () {
          return '結果がありませんでした';
        },
        searching: function () {
          return '検索しています…';
        },
        removeAllItems: function () {
          return 'すべてのアイテムを削除';
        },
      };
    }),
      n.define,
      n.require;
  })();
});
