import consumer from "./consumer"

consumer.sk_progress_bar = consumer.subscriptions.create(
  {
    channel: "SkProgressBarChannel",
    room: "sk_progress_bar_channel"
  }, {
  connected: () => {

  },
  disconnected: () => {},
  received: (data) => {
    document.getElementById("fade").classList.remove("fade", "d-none")
    let progress_status;
    progress_status = data.progress_status;
    if (progress_status >= 100) {
      document.getElementById("flash").innerText = "インポートが完了しました"
    }
    return document.getElementById("sk_progress_bar").style.width = `${progress_status}%`;
  }
});
