import { Controller } from "stimulus"

export default class extends Controller {
    connect() {
      console.log("connected to scroll")
      const messages = document.getElementById("messages");
      messages.addEventListener("DOMNodeInserted", this.resetScroll)
      this.resetScroll(messages);
    }
    
    disconnect() {
      
    }
    
    resetScroll() {
      console.log("scrolled")
      messages.scrollTop = messages.scrollHeight - messages.clientHeight;
    }
}